import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      books: []
    };
  }

  componentDidMount() {
    fetch('/books.json')
      .then(response => response.json())
      .then(data => this.setState({ books: data }));
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, books } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <header>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component={Link} to="/" style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
              Lob A Cob
            </Typography>
            <Button color="inherit" component={Link} to="/">Home</Button>
            <IconButton edge="end" color="inherit" onClick={this.handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={this.handleMenuClose}
            >
              <MenuItem component={Link} to="/Magic" onClick={this.handleMenuClose}>Magic</MenuItem>
              <MenuItem component={Link} to="/books" onClick={this.handleMenuClose}>Books List</MenuItem>

              {books.map(book => (
                <MenuItem key={book.Id} component={Link} to={`/book/${book.Id}`} onClick={this.handleMenuClose}>
                  {book.Title}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      </header>
    );
  }
}