import Home from "./components/Home";
import { BooksList } from './components/BooksList';
import BookDetails  from './components/BookDetails';
import {FetchData} from "./components/FetchData";
import PaymentPage from "./components/PaymentPage";
import SuccessPage from "./components/SuccessPage";
import Sitemap from './components/Sitemap';
import ComperitionList from "./components/ComperitionList";
import Comperition from "./components/Comperition";
import MagicFinderPage from "./components/MagicFinderPage";
import WaitingListSignUpPage from "./Pages/WaitingListSignUpPage";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/books',
    element: <BooksList />
  },
  {
    path: '/book/:id',
    element: <BookDetails />
  },
  {
    path: '/comperitions',
    element: <ComperitionList />
  },
  {
    path: '/comperition/:id',
    element: <Comperition />
  },
  {
    path: '/sweater',
    element: <FetchData />
  },
  {
    path: '/payment',
    element: <PaymentPage />
  },
  {
    path: '/success',
    element: <SuccessPage />
  },
  {
    path: '/sitemapbuilder',
    element: <Sitemap />
  },
  {
    path: '/magic',
    element: <MagicFinderPage />
  },
  {
    path: '/magic/WaitingList',
    element: <WaitingListSignUpPage />
  }
];

export default AppRoutes;
