import React from 'react';
import WaitingList from '../components/WaitingList';
import {Container, Paper} from "@mui/material";

const WaitingListSignUpPage = () => {
    return (
        <Container sx={{
            padding: '5px',
            background: '#ffffff',
        }}>
            <Paper variant="elevation">
                <div>
                    <h1>Sign Up for the Waiting List</h1>
                    <p>Help the direction of future competitions</p>
                    <p>Sign up for the waiting list to be the first to know about new competitions and to help us decide
                        what to
                        offer next.</p>
                    <WaitingList/>
                </div>
            </Paper>
        </Container>
    );
};

export default WaitingListSignUpPage;