import React from 'react';
import { Container, Typography,  Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const isGamesReady = false; // Change this to true when the pages are ready
  const isCompsReady = false; // Change this to true when the pages are ready
  const handleMouseEnter = (gradientClass) => {
    const rootElement = document.getElementById('root');
    rootElement.className = '';
    rootElement.classList.add(gradientClass);
  };

  return (
    <main>
      <Container style={{ marginTop: '30px' }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Welcome to Lob a Cob
        </Typography>
        <Grid container spacing={3} className="gridContainer">
          {isCompsReady && (
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ padding: '20px' }}
                onMouseEnter={() => handleMouseEnter('comps')}
              >
                <Typography variant="h6" component="h3" gutterBottom>
                  Competitions
                </Typography>
                <Typography variant="body1">
                  Running competitions to win exciting prizes.
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Link to="/books" style={{ textDecoration: 'none' }}>
              <Paper
                className="card"
                style={{ padding: '20px' }}
                onMouseEnter={() => handleMouseEnter('books')}
              >
                <Typography variant="h6" component="h3" gutterBottom>
                  Books
                </Typography>
                <Typography variant="body1">
                  Discover a wide range of books across various genres and categories.
                </Typography>
              </Paper>
            </Link>
          </Grid>
          {isGamesReady && (
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ padding: '20px' }}
                onMouseEnter={() => handleMouseEnter('games')}
              >
                <Typography variant="h6" component="h3" gutterBottom>
                  Games
                </Typography>
                <Typography variant="body1">
                  Explore a variety of games for all ages and preferences.
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Link to="/magic" style={{ textDecoration: 'none' }}>
              <Paper
                className="card"
                style={{ padding: '20px' }}
                onMouseEnter={() => handleMouseEnter('magic')}
              >
                <Typography variant="h6" component="h3" gutterBottom>
                  Magic Finder
                </Typography>
                <Typography variant="body1">
                  Find the magic in your surroundings.
                </Typography>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Home;