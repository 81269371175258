import React, { useEffect, useState } from 'react';
import { Paper, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import SignalStrength from "./SignalStrength";

const DisneyDistances = () => {
  const [distances, setDistances] = useState(null);
  const [error, setError] = useState(null);
  const [unit, setUnit] = useState('mi');
  // const [currentLocation, setCurrentLocation] = useState({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          // setCurrentLocation({ latitude, longitude });
          try {
            const response = await fetch('/DisneyDistances.json');
            const DisneyDistancesJson = await response.json();
            let output = {
            }

            for (const park of DisneyDistancesJson.parks) {
              park.distance = calculateDistance(latitude, longitude, park.latitude, park.longitude, unit);
              output[park.name] = park.distance;
            }
            setDistances(output);
          } catch (err) {
            setError('Failed to fetch distances');
          }
        },
        (err) => {
          setError('Failed to get your location');
        }
      );
    } else {
      setError('Geolocation is not supported by this browser');
    }
  }, [unit]);

  const handleUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setUnit(newUnit);
    }
  };

  if (error) {
    return <Typography variant="body1">{error}</Typography>;
  }

  if (!distances) {
    return <Typography variant="body1">Loading distances...</Typography>;
  }

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6">Your current Distances to Disney Parks</Typography>
      <ToggleButtonGroup
        value={unit}
        exclusive
        onChange={handleUnitChange}
        aria-label="distance unit"
      >
        <ToggleButton value="mi" aria-label="miles">
          Miles
        </ToggleButton>
        <ToggleButton value="km" aria-label="kilometers">
          Kilometers
        </ToggleButton>
      </ToggleButtonGroup>
      <ul>
        {/*<li>Your current location: {currentLocation.latitude}, {currentLocation.longitude}</li>*/}
        {Object.entries(distances)
          .sort(([, distanceA], [, distanceB]) => distanceA - distanceB)
          .map(([park, distance]) => (
              <li key={park}>
                <SignalStrength strength={convertDistanceToClosestUnit(distance)} />
                {park}: {distance.toFixed(2)} {unit}
              </li>
          ))}
      </ul>
    </Paper>
  );
};

function convertDistanceToClosestUnit(distance) {
  let output = 0;  
  
  if (distance < 25) {
        output = 4;
    }
    else if (distance < 340) {
        output = 3;
    }
    else if (distance < 5000) {
        output = 2;
    }
    else {
        output = 1;
    }
  return output;
}

function calculateDistance(lat1, lon1, lat2, lon2, unit = 'km') {
  const earthRadiusKm = 6371; // Earth's radius in kilometers
  const earthRadiusMi = 3959; // Earth's radius in miles

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = (unit === 'mi') ? earthRadiusMi * c : earthRadiusKm * c;
  return distance;
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}
export default DisneyDistances;