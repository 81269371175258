import React, {useState} from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper, Typography, Box, CircularProgress
} from '@mui/material';
import config from '../config';

const baseUrl = config.apiBaseUrl;

const WaitingList = () => {
    const [email, setEmail] = useState('');
    const [items, setItems] = useState({
        ears: false,
        clothes: false,
        toys: false,
        vouchers: false,
        bags: false
    });
    const [priceRange, setPriceRange] = useState('');
    const [SubscriptionPriceRange, setSubscriptionPriceRange] = useState('');
    const [NumberOfEntries, setNumberOfEntries] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        document.getElementById("overlayform").style.display = "flex";
        const selectedItems = Object.keys(items).filter(item => items[item]);
        const data = {
            email,
            items: selectedItems,
            priceRange,
            SubscriptionPriceRange,
            NumberOfEntries
        };
        try {
            const response = await fetch(`${baseUrl}/api/Competition/waitinglist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                alert('Successfully submitted!');
                // redirect to magic finder page
                
            } else {
                alert('Failed to submit!');
            }
        } catch (error) {
            console.error('Error submitting the form', error);
            alert('Failed to submit!');
        }
        
        document.getElementById("overlayform").style.display = "none";
    };

    const handleItemChange = (event) => {
        setItems({
            ...items,
            [event.target.name]: event.target.checked
        });
    };

    const formOverlayStyle = {
        position: 'fixed', /* Sit on top of the page content */
        display: 'none', /* Hidden by default */
        width: '100%', /* Full width (cover the whole page) */
        height: '100%', /* Full height (cover the whole page) */
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)', /* Black background with opacity */
    zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
    cursor: 'pointer', /* Add a pointer on hover */
        justifyContent: 'center',
    alignItems: 'center'
};
    
    return (
        <Paper>
            
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <FormGroup>
                    <Typography variant="h4">One off competitions</Typography>
                    <Typography variant="body1">Select the items you are interested in for future compilations or suggest things you would like to see</Typography>
                    <FormControlLabel
                        control={<Checkbox value={items.ears} checked={items.ears} onChange={handleItemChange}
                                           name="ears"/>}
                        label="Ears"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.clothes} checked={items.clothes} onChange={handleItemChange}
                                           name="clothes"/>}
                        label="Clothes"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.bags} checked={items.bags} onChange={handleItemChange}
                                           name="bags"/>}
                        label="bags"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.toys} checked={items.toys} onChange={handleItemChange}
                                           name="toys"/>}
                        label="Toys"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.vouchers} checked={items.vouchers} onChange={handleItemChange}
                                           name="vouchers"/>}
                        label="Vouchers"
                    />
                    <FormControlLabel
                        control={<Checkbox value={items.other} checked={items.other} onChange={handleItemChange}
                                           name="other"/>}
                        label="Other"
                    />
                    {items.other && (
                        <TextField
                            label="Other Item"
                            value={items.otherText || ''}
                            onChange={(e) => setItems({...items, otherText: e.target.value})}
                            fullWidth
                            margin="normal"
                        />
                    )}


                    <FormControl fullWidth margin="normal">
                        <InputLabel>Price Range</InputLabel>
                        <Select
                            value={priceRange}
                            onChange={(e) => setPriceRange(e.target.value)}
                            required
                        >
                            <MenuItem value="low">Low (£0 - £3)</MenuItem>
                            <MenuItem value="medium">Medium (£3 - £8)</MenuItem>
                            <MenuItem value="high">High (£9 - £15)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <Typography variant="h4">Monthly competitions</Typography>
                    <Typography variant="body1">If there was a subscript where you could get a number of entries per month what price and how many entries</Typography>
                    
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Subscription Price Range</InputLabel>
                        <Select
                            value={SubscriptionPriceRange}
                            onChange={(e) => setSubscriptionPriceRange(e.target.value)}
                            required
                        >
                            <MenuItem value="low">Low (£4.99 - £8.99)</MenuItem>
                            <MenuItem value="medium">Medium(£9.99 - £15.99)</MenuItem>
                            <MenuItem value="high">High(£16.99 - £24.99)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Number of Entries</InputLabel>
                        <Select
                            value={NumberOfEntries}
                            onChange={(e) => setNumberOfEntries(e.target.value)}
                            required
                        >
                            <MenuItem value="low">Low (4 - 8)</MenuItem>
                            <MenuItem value="medium">Medium (9 - 18)</MenuItem>
                            <MenuItem value="high">High (19 - 25)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </form>
            <Box id="overlayform" sx={formOverlayStyle}><CircularProgress color="secondary" size={140} /></Box>
        </Paper>
    );
};

export default WaitingList;