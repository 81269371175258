import './SignalStrength.css';

const SignalStrength = (input) =>
{
    const strengthValue = `signal-bar strength-${input.strength}` // Replace with your logic
    
    const singlenBarIndicatorClass = input.Invert ? "signal-bar-indicator-inverted" : "signal-bar-indicator";
    
    return (
        <div className={strengthValue}>
            <div className={singlenBarIndicatorClass}></div>
        </div>
    );
}

export default SignalStrength;